<template>
  <div class="flex justify-center items-center ">
    <Button
      id="links"
      type="button"
      class="!rounded-tr-none !rounded-br-none border !h-8"
      :class="[selected_analytic_type === analytic_types.LINK ? 'text-white bg-[#2560D7] !border-[#013D87] !border-r-transparent' : '!text-[#757A8A] !bg-white']"
      buttonClass="btn-sm"
      @click.prevent="changeAnalytics(analytic_types.LINK)"
    >
      <template v-slot:label>{{ getSiteDetails.agency_name }} Links</template>
    </Button>
    <Button
      id="biolinkss"
      type="button"
      class="!rounded-tl-none !rounded-bl-none !h-8 border"
      :class="[selected_analytic_type === analytic_types.LINK ? '!text-[#757A8A] !bg-white' : 'text-white bg-[#2560D7] !border-[#013D87] !border-l-transparent']"
      buttonClass="btn-sm"
      @click.prevent="changeAnalytics(analytic_types.BIO_LINK)"
    >
      <template v-slot:label>Bio Links</template>
    </Button>
  </div>
</template>
<script>
import {ANALYTIC_TYPE} from "@/common/constants";
export default {
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
  },
  props: {
    value: {
      type: String,
      default: ANALYTIC_TYPE.LINK
    }
  },
  data() {
    return {
      analytic_types: ANALYTIC_TYPE
    }
  },
  computed: {
    selected_analytic_type() {
      return this.value
    }
  },
  created() {
    const analyticsType = this.fetchAnalyticType()
    this.changeAnalytics(analyticsType)
  },
  methods: {
    fetchAnalyticType() {
      if (this.$route.query.hasOwnProperty('analytic_type')) {
        const analytic_type = this.$route.query.analytic_type;
        // selected analytic type is not one of the replug links or bio links
        if (analytic_type === ANALYTIC_TYPE.BIO_LINK) {
          return ANALYTIC_TYPE.BIO_LINK;
        }
      }
      return ANALYTIC_TYPE.LINK;
    },
    changeAnalytics(analyticsType) {
      if (Object.values(ANALYTIC_TYPE).indexOf(analyticsType) === -1) {
        analyticsType = ANALYTIC_TYPE.LINK
      }
      if (this.fetchAnalyticType() !== analyticsType) {
        const query = {query: {...this.$route.query, analytic_type: analyticsType}};
        this.$router.replace(query)
      }
      this.$emit('input', analyticsType)
    },
  }
}
</script>
